.MuiDataGrid-main {
    border-top: 1px solid rgba(224, 224, 224, 1);
}

.page-total {
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
}

.MuiDataGrid-columnHeaderTitle {
    color: #3484a2;
    font-weight: 600 !important;
    text-transform: uppercase;
}

.MuiDataGrid-toolbarContainer {
    justify-content: flex-end;
    float: right;
    padding: 0.5rem 0;
    @media screen and (max-width: 768px) {
        justify-content: center;
        float: unset;
        flex-wrap: nowrap;
    }
}

.MuiDataGrid-columnHeadersInner {
    .MuiDataGrid-columnHeader {
        .MuiDataGrid-columnSeparator {
            color: #4B5563 !important;
        }
        &:last-child {
            .MuiDataGrid-columnSeparator {
                display: none;
            }
        }
    }
}

.MuiDataGrid-main > div:first-child {
    pointer-events: unset !important;
}